import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation";
import AdPageHero from "../components/ad-page-hero";
import BasicLayout from "../components/basic-layout";
import ImageFrame from "../images/Frame.svg";
import { Button } from "react-bootstrap";
import ReviewCardAd from "../components/review-card-ad";
import ReviewSectionAd from "../components/review-section-ad";
import { InlineWidget } from "react-calendly";
import blueOne from "../images/1-blue.svg";
import blueTwo from "../images/2-blue.svg";
import blueThree from "../images/3-blue.svg";

const AdPageDubaiTemplate = ({ data: { post, site, otherPosts, blogImg } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Landing",
				item: {
					url: `${siteUrl}/dubai/landing`,
					id: `${siteUrl}/dubai/landing`,
				},
			},
			{
				"@type": "ListItem",
				position: "4",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/dubai/landing/${post.slug}`,
					id: `${siteUrl}/dubai/landing/${post.slug}`,
				},
			},
		],
	};

	const reviews = [
		{
			name: post.adPageFieldsDubai?.reviews.name1,
			text: post.adPageFieldsDubai?.reviews.text1,
		},
		{
			name: post.adPageFieldsDubai?.reviews.name2,
			text: post.adPageFieldsDubai?.reviews.text2,
		},
		{
			name: post.adPageFieldsDubai?.reviews.name3,
			text: post.adPageFieldsDubai?.reviews.text3,
		},
	];
	return (
		<BasicLayout dubai>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={post?.seo?.title}
				description={post?.seo?.metaDesc}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/dubai/landing/${post.slug}`,
					title: `${post?.seo?.opengraphTitle}`,
					description: `${post?.seo?.opengraphDescription}`,
					images: [
						{
							url: `${post?.seo?.twitterImage?.link}`,
							width: `${post?.seo?.twitterImage?.mediaDetails.width}`,
							height: `${post?.seo?.twitterImage?.mediaDetails.height}`,
							alt: `${post?.seo?.twitterImage?.altText}`,
						},
					],
				}}
			/>

			<AdPageHero
				dubai
				title={
					<h1 className="pt-3 mb-3 text-uppercase ssp-bold display-1 hero-title">
						{post.title}
					</h1>
				}
			/>
			<section style={{ background: "#FDFDFD" }} className="py-5 py-lg-7">
				<Container>
					<Row className="g-5 g-lg-7 align-items-center justify-content-between">
						<Col xl={5} lg={6}>
							{" "}
							<h2 className="display-5 text-primary mb-4">
								{post.adPageFieldsDubai.section1.title}
							</h2>
							{parse(post.adPageFieldsDubai.section1.text)}
							<Button
								className=" w-100 cta-btn w-md-auto mt-4 fs-5 primary-link-dark py-2 px-4 "
								variant="secondary"
								href="/dubai/contact-us"
							>
								Speak to our team
							</Button>
						</Col>
						<Col lg={{ span: 6, order: "last" }} xl={5}>
							<div style={{ overflow: "hidden" }} className="position-relative">
								<img
									src={ImageFrame}
									alt="Image Frame"
									style={{
										width: "100%",

										zIndex: 2,
									}}
									className="w-100 position-relative "
								/>
								<GatsbyImage
									style={{ zIndex: 1, width: "98%" }}
									className="position-absolute top-50 start-50 translate-middle "
									image={post.adPageFieldsDubai.section1.image.gatsbyImage}
									alt={post.adPageFieldsDubai.section1.image.altText}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section
				style={{ background: "#FDFDFD" }}
				className="pb-5 pt-lg-5 pb-lg-7"
			>
				<Container>
					<Row className="justify-content-center">
						<Col lg={8} xl={6} className="text-lg-center ">
							<h2 className="display-5 text-primary mb-4">
								{post.adPageFieldsDubai.section2.title}
							</h2>
							{parse(post.adPageFieldsDubai.section2.text)}
							<Button
								className="mt-5 cta-btn fs-5 w-100 w-md-auto py-2 px-4 primary-link-dark"
								variant="secondary"
								href={post.adPageFieldsDubai.section2.learnMoreLink}
							>
								Learn more
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
				<Container className="">
					<Row className="gx-xl-4 g-6 justify-content-center">
						<Col className="position-relative" md={6} lg={4}>
							<div
								style={{ top: "-7rem" }}
								className="position-absolute text-center w-100 h-100"
							>
								<img
									src={blueOne}
									
									className="w-md-40 w-30"
								/>
							</div>
							<div className="px-xl-5 pt-5 position-relative">
								<h3 className="fs-2 text-white">
									{post.adPageFieldsDubai.section3.title1}
								</h3>
								<p className="text-white ssp-light">
									{post.adPageFieldsDubai.section3.text1}
								</p>
							</div>
						</Col>
						<Col className="position-relative" md={6} lg={4}>
							<div
								style={{ top: "-7rem" }}
								className="position-absolute text-center w-100 h-100"
							>
									<img
									src={blueTwo}
									
									className="w-md-40 w-30"
								/>
							</div>
							<div className="px-xl-5 pt-5 position-relative">
								<h3 className="fs-2 text-white">
									{post.adPageFieldsDubai.section3.title2}
								</h3>
								<p className="text-white ssp-light">
									{post.adPageFieldsDubai.section3.text2}
								</p>
							</div>
						</Col>
						<Col className="position-relative " md={6} lg={4}>
							<div
								style={{ top: "-7rem" }}
								className="position-absolute w-100 text-center h-100"
							>
										<img
									src={blueThree}
									
									className="w-md-40 w-30"
								/>
							</div>
							<div className="px-xl-5 pt-5 position-relative">
								<h3 className="fs-2 text-white">
									{post.adPageFieldsDubai.section3.title3}
								</h3>
								<p className="text-white ssp-light">
									{post.adPageFieldsDubai.section3.text3}
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			{post.adPageFieldsDubai.hasReviews === true && (
				<section className="py-5 py-lg-7">
					<ReviewSectionAd reviews={reviews} />
				</section>
			)}
			<section style={{ background: "#FDFDFD" }} className="py-5 py-lg-7">
				<Container>
					<Row className="g-5 g-lg-8 align-items-center justify-content-between">
						<Col lg={{ span: 6 }} xl={6}>
							<div style={{ overflow: "hidden" }} className="position-relative">
								<img
									src={ImageFrame}
									alt="Image Frame"
									style={{
										width: "100%",

										zIndex: 2,
									}}
									className="w-100 position-relative "
								/>
								<GatsbyImage
									style={{ zIndex: 1, width: "98%" }}
									className="position-absolute top-50 start-50 translate-middle "
									image={post.adPageFieldsDubai.section4.image.gatsbyImage}
									alt={post.adPageFieldsDubai.section4.image.altText}
								/>
							</div>
						</Col>
						<Col xl={6} lg={6}>
							{" "}
							<h2 className="display-5 text-primary mb-4">
								{post.adPageFieldsDubai.section4.title}
							</h2>
							<div className="text-primary">
								{parse(post.adPageFieldsDubai.section4.text)}
							</div>
							<Button
								className=" w-100 cta-btn w-md-auto mt-4 fs-5 primary-link-dark py-2 px-4 "
								variant="secondary"
								href="/dubai/contact-us"
							>
								Get in touch today
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
				<Container>
					<Row id="form">
						<Col className="text-center">
							{" "}
							<h2 className="display-5 text-primary mb-3">
								Talk to an expert
							</h2>
							<p>{post.adPageFieldsDubai.discoveryMeetingText}</p>
						</Col>
					</Row>
					<Row>
						<Col className="d-xl-none">
							<InlineWidget
								className="calender"
								styles={{ minWidth: "100%", height: "1150px" }}
								url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
							/>
						</Col>
						<Col className="d-none d-xl-block">
							<InlineWidget
								className="calender"
								styles={{ minWidth: "100%", height: "700px" }}
								url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
							/>
						</Col>
					</Row>
				</Container>
			</section>
		</BasicLayout>
	);
};

export default AdPageDubaiTemplate;

export const pageQuery = graphql`
	query AdPageDubaiById($id: String!) {
		post: wpDubaiAdPage(id: { eq: $id }) {
			title
			slug
			seo {
				metaDesc
				title
				twitterImage {
					altText
					mediaDetails {
						height
						width
					}
					link
					gatsbyImage(
						width: 1920
						quality: 75
						formats: [WEBP]
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
				opengraphTitle
				opengraphDescription
			}
			adPageFieldsDubai {
				discoveryMeetingText
				hasReviews
				reviews {
					name1
					name2
					name3
					text1
					text2
					text3
				}
				section1 {
					title
					text
					image {
						altText
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				section2 {
					text
					title
					learnMoreLink
				}
				section3 {
					text1
					text2
					text3
					title1
					title2
					title3
				}
				section4 {
					text
					title
					image {
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
						altText
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
